<template>
  <section class="p-3">
    <h2>Nothing here...</h2>
    <p>
      Only the void is located by this address.
    </p>
  </section>
</template>

<script>
export default {
  name: "NotFound"
}
</script>

<style scoped>

</style>
