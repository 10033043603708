<template>
  <main id="app" class="App m-0 p-0">
    <router-view :key="$route.fullPath"></router-view>
  </main>
</template>

<script>

export default {
  name: 'App',
  components: {},
}

</script>

<style>
</style>
