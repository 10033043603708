<template>
  <section class="page-container">
    <nav class="header d-flex justify-content-start">
      <div>
        <img src="../assets/picturevert/logo.png" class="app-logo" alt="App logo">
      </div>
      <div class="header-text">
        <h2>Picturevert</h2>
        <h6>Your pocket instant art creator</h6>
      </div>
    </nav>
    <section class="d-flex justify-content-between col-12 main-content">
      <section class="description-content">
        <h2>Turn your photos into outlandish works of art by a simple color inversion!</h2>
        <p>
          Picturevert is a simple app produces photo negatives (inverted colors) from selected photos. Its simplistic interface provides maximum effect with minimum effort!
        </p>
        <p>
          With just a few taps your photos can be converted into a negatives with a varied degree of contrast, that would help presenting your pictures in an unexpected light.
        </p>
        <h4>Features:</h4>
        <ul>
          <li>Selecting any image from the photo gallery.</li>
          <li>Ability to take an instant photo and convert it into a negative immediately.</li>
          <li>Inverting image colors with a variable degree of color shift</li>
          <li>Exporting the result by using standard sharing mechanism.</li>
        </ul>
        <h4>Why should I use it?</h4>
        <p>
          Picturevert is simple, easy to use and provides an amazing opportunity to turn your photos into something completely different and unexpected.
        </p>
        <h4>What does it cost?</h4>
        <p>Nothing! The app is completely free in both iOS AppStore and Android Play Store. And it's open-source too - please see <a href="https://github.com/hydralien/PictureVert">its code on GitHub</a></p>
        <h4>Get it now for free!</h4>
        <div class="d-flex flex-wrap justify-content-around">
          <div class="store-block">
            <a href="https://apps.apple.com/app/picturevert/id1630520634">
              <img src="../assets/app-store.png" alt="Apple App Store" class="store-icon"> From Apple App Store
            </a>
          </div>
          <div class="store-block">
            <a href="https://play.google.com/store/apps/details?id=net.hydralien.picturevert">
              <img src="../assets/google-play.png" alt="Google Play Store" class="store-icon"> From Google Play Store
            </a>
          </div>
        </div>
      </section>
      <section class="slider">
        <VueSlickCarousel :arrows="true" :dots="true" v-bind="carouselSettings">
          <div><img src="../assets/picturevert/screenshots/landing.png"></div>
          <div><img src="../assets/picturevert/screenshots/invert.png"></div>
          <div><img src="../assets/picturevert/screenshots/export.png"></div>

          <template #prevArrow="" class="prev-arrow">
            <div class="custom-arrow">
              Prev
            </div>
          </template>
          <template #nextArrow="" class="next-arrow">
            <div class="custom-arrow">
              Next
            </div>
          </template>
        </VueSlickCarousel>
      </section>
    </section>
    <footer class="d-flex justify-content-between footer">
      <div class="copyright">
        © <a href="https://hydralien.net">Borys Turchyk</a>, 2022
      </div>
      <div class="contacts">
        <span>Contact:</span>
<!--        <a href="https://facebook.com/pidgingapp/">-->
<!--          <font-awesome-icon :icon="['fab', 'facebook-square']"/>-->
<!--        </a>-->
        <a href="mailto:hydra.public@gmail.com">
          <font-awesome-icon icon="envelope-square"/>
        </a>
      </div>
    </footer>
  </section>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
  name: "Picturevert",
  components: {
    VueSlickCarousel
  },
  metaInfo: {
    title: `Picturevert - Image Inversion App`,
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content: "iOS/Android Image Inversion app that produces photo negatives (inverted colors) from selected photos"
      },
    ],
    link: [
      { rel: 'favicon', href: '/picturevert/favicon.ico' }
    ]
  },
  data: () => {
    return {
      carouselSettings: {
        "dots": true,
        "arrows": true,
        "infinite": false,
        "speed": 500,
        "slidesToShow": 2,
        "slidesToScroll": 1,
        "initialSlide": 0,
        "responsive": [
          {
            "breakpoint": 500,
            "settings": {
              "slidesToShow": 1,
              "slidesToScroll": 1,
              "infinite": true,
              "dots": true
            }
          }
        ]
      }
    }
  }
}
</script>

<style scoped>
.app-logo {
  width: 70px;
  height: 70px;
  margin: 0 1rem;
  border-radius: 1em;
}

.main-content {
  width: 100%;
  flex: 1 0 auto;
}

.description-content {
  display: inline-block;
  padding: 1rem 0 1rem 1rem;
}

.description-content h2 {
  margin-bottom: 1rem;
}

.description-content h4 {
  margin-top: 1.5rem;
}

.slider {
  max-width: 600px;
  display: inline-block;
  margin: 0 2rem;
  padding: 0 2rem;
}

.slider img {
  width: 250px;
  border: 1px solid lightgrey;
  border-radius: 10px;
}

.header {
  padding: 1rem;
}

.header h2 {
  color: rgb(38, 57, 119);
  margin: 0;
}

.header h6 {
  color: rgb(38, 99, 154);
}

.custom-arrow::before {
  color: darkslategray;
}

.header-text {
  padding: 5px 0;
}

.store-icon {
  width: 60px;
}

.footer {
  padding: 1rem 2rem;
  margin-top: 2rem;
  /*position: fixed;*/
  bottom: 0;
  width: 100vw;
  flex-shrink: 0;
}

.page-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.contacts a {
  font-size: 2rem;
  margin: 0 0.3rem;
  vertical-align:middle
}

.copyright {
  padding: 0.5em 0;
}

p {
  text-indent: 1em;
}

.block-disabled {
  filter: grayscale(1);
}

@media (max-width: 576px) {
  .slider {
    width: 300px;
    display: block;
    margin: 0 1rem;
    padding: 0 1rem;
  }
  .description-content {
    display: block;
  }
  .main-content {
    flex-wrap: wrap;
  }
  .footer {
    padding: 1rem 1rem;
  }
}
</style>
